import { FC } from 'react';
import { dataAttributeProps } from '../../utils/ComponentUtils';
import Tooltip from './Tooltip';

type ProgressBarProps = {
  progress: number; // between 0 and 1
  size?: ProgressBarSize;
  disabled?: boolean;
  useProgressColours?: boolean;
};

export enum ProgressBarSize {
  S,
  M,
  L,
}

const ProgressBar: FC<ProgressBarProps> = (props) => {
  const { progress, disabled, useProgressColours } = props;
  const value: string = (progress * 100).toFixed(0) + '%';
  const completed: boolean = progress === 1;
  const barColoursCss = useProgressColours
    ? progress < 0.4
      ? 'bg-semantic-2'
      : progress > 0.7
        ? 'bg-semantic-1'
        : 'bg-semantic-3'
    : completed
      ? 'bg-semantic-1'
      : 'bg-semantic-3';
  return (
    <Tooltip text={disabled ? undefined : value}>
      {(tooltip) => (
        <div
          {...tooltip}
          {...dataAttributeProps(props)}
          className={`bg-opacity-10 ${disabled ? 'bg-gray-2' : completed ? 'bg-progress-bar-1' : 'bg-progress-bar-2'} max-h-2 w-full rounded`}
        >
          <div
            style={{ width: value }}
            className={`bg-opacity-100 ${disabled ? 'bg-gray-2' : barColoursCss} h-2 rounded transition-all duration-300 ease-in-out`}
          />
        </div>
      )}
    </Tooltip>
  );
};

export default ProgressBar;
